<template>
  <ion-page>
    <ion-header>
      <MainToolbar :title="i18n.$t('analysis.title.subjects')" />
    </ion-header>
    <ion-content id="analysis-scroll-content">
      <ion-card class="toggle-item" v-if="enableGroupByStatus">
        <ion-card-header>
          <ion-card-title class="text-with-icon"><font-awesome-icon class="toggle-title-icon swap-positions-icon" :icon="faArrowRightArrowLeft" />{{ i18n.$t('analysis.group.title') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content :class="['toggle-container', groupByStatus ? 'checked' : undefined]">
          
          <font-awesome-icon :icon="faLocationDot" class="toggle-select-icon toggle-unchecked-icon" />
          <ion-label class="toggle-unchecked">{{ i18n.$t('analysis.group.location') }}</ion-label>
          <ion-toggle id="grouping-toggle" mode="ios" :checked="groupByStatus" @ionChange="groupByStatus = $event.target.checked"></ion-toggle>
          <ion-label class="toggle-checked">{{ i18n.$t('analysis.group.status') }}</ion-label>
          <font-awesome-icon :icon="faDiagramPredecessor" class="toggle-select-icon toggle-checked-icon" />
        </ion-card-content>
      </ion-card>
      
      <div class="subject-search-bar">
        <ion-searchbar :placeholder="i18n.$t('analysis.search.subject')" @keyup="blurOnEnter" @ionInput="searchAnimalInput = $event.target.value"></ion-searchbar>
      </div>

      <ion-card class="hint-card" v-if="!areAnimalsAvailable">
        <ion-item lines="none">
          <ion-label>
            {{ i18n.$t('analysis.subject.no_animals_create.before') }}
            <mark class="add-icon-hint-container"><ion-icon :icon="add" class="add-icon-hint"></ion-icon></mark>
            {{ i18n.$t('analysis.subject.no_animals_create.after') }}
          </ion-label>
        </ion-item>
      </ion-card>

      <template v-for="([groupKey, group]) in sortOrderCriterion(currentOrderCriterion)" :key="groupKey">
        <ion-card class="subject-list-card">
          <ion-list :class="['subject-list', (groupByStatus) ? 'status-list' : undefined]"> <!-- TODO Make sorting of horses everywhere the same !!! -->
            <!-- List is open, if it is a valid group, or if no groups are set, or if it is filtered and none are found in the valid groups -->
             <!-- FIXME Rethink default visibility and with search : OLD added as OR: (!doesAnyGroupContainFilteredAnimals && doesUnassignedContainFilteredAnimals) -->
            <CollapsibleList class="collapsible-list" :open="(group.descriptor != null && !(group.defaultClosed)) || (!areOrderGroupsAvailable)"
              :showHeader="areOrderGroupsAvailable"
              showZeroIndicator
              :primaryIndicator="group.unfilteredCount"
              :primaryIcon="horseIcon"
              :tertiaryIndicator="getSearchCount(group)"
              :tertiaryIcon="searchSharp"
              :showNoItemsIndicator="true"
              :noItemsIndicator="i18n.$t('analysis.subject.none_found')"> <!-- TODO Use primary indicator to show new horses in the unassigned ones. Maybe implement a flag that shows if the horse has been interacted with? --> <!-- TODO Add card on top if there are messages in the inbox -->
              <template v-slot:label>
                <div class="subject-list-header">
                  <ion-card-title v-if="group.descriptor != null" class="collapsible-list-label group-label">{{ group.descriptor }}</ion-card-title>
                  <ion-card-title v-else class="collapsible-list-label unset-group-label">{{ i18n.$t(`analysis.subject.${ groupByStatus ? 'not_present' : 'unassigned'}`) }}</ion-card-title>
                  <ion-button v-if="group.descriptor != null" fill="clear" shape="round" class="edit-group-button" @click.stop="groupByStatus ? editStatus(group) : editLocation(group)">
                    <ion-icon :icon="pencil" slot="icon-only"></ion-icon>
                  </ion-button>
                  <!-- TODO Add popup for creating/editing a location with spots -->
                  <!-- TODO Maybe allow reordering of the locations in the future -->
                  <!-- TODO Add/Edit location popup has option to delete. Keep in mind, that two spots in the same location can't have the same name! -->
                  <!-- TODO When implementing removal of location hose is not assigned anymore. Treat not existing locations/spots as none when generating the horse list. And warn user with a popup when deleting location, that horses get unassigned. -->
                </div>
              </template>

              <template v-for="([secondLevelGroupKey, secondLevelGroup]) of sortOrderCriterion(getSecondLevelOrderCriterion(group))" :key="secondLevelGroupKey"> <!-- TODO Test removal of spot while horse is assigned! Update the warning when deleting to reflect current behaviour. -->
                <ion-item-divider :id="createUniqueItemID(getUUID(group, secondLevelGroup))" :ref="(el) => setUUIDItemReference(el)" v-if="secondLevelGroup.count > 0 || searchAnimalInput == null || searchAnimalInput.length <= 0" class="second-level-divider">
                  <ion-button :class="['qr-button', (getUUID(group, secondLevelGroup) == null) ? 'unassigned' : undefined]" fill="clear" shape="round" @click="generateSingleQR(group, secondLevelGroup, groupByStatus)">
                    <ion-icon :icon="qrCode" class="qr-icon"></ion-icon>
                    <ion-icon :icon="add" class="add-qr-icon-addition"></ion-icon>
                  </ion-button>
                  {{ secondLevelGroup.descriptor }}
                </ion-item-divider> <!-- TODO Add option (button) to add a location to each spot on the map with a popup showing the map. Maybe add overview map later. Or alternatively just a description text. Indicate in the button if a map is set or not for that spot. -->
                <template v-if="secondLevelGroup.count > 0">
                  <ion-item-sliding v-for="(animal, animalIndex) of getAnimals(secondLevelGroup.animals)" :ref="(element) => animalSlidingItemRef(animal, groupByStatus, element)" :key="animalIndex" class="horse-list-item" :disabled="!enableGroupByStatus">
                    <ion-item class="outer-horse-item" lines="full">
                      <ion-item class="inner-horse-item" lines="none" detail button @click="chooseSubject(animal.id)">
                        <ion-avatar class="avatar" slot="start">
                          <AuthenticatedMedia v-if="animal.instance.image != null" :mediaUrl="animal.instance.image.blobURL" type="image"></AuthenticatedMedia>
                          <div v-else class="avatar-icon">
                            <ion-icon :icon="horseIcon"></ion-icon>
                          </div>
                        </ion-avatar>
                        <ion-label>
                          <h2>{{ animal.instance.name }}</h2>
                          <div class="extra-animal-info">
                            <h3 class="text-with-icon">
                              <font-awesome-icon :icon="faHashtag" />
                              {{ animal.instance.unique_identifier || noValuePlaceholder }}
                            </h3>
                            <h3 class="text-with-icon">
                              <font-awesome-icon :icon="faMarsAndVenus" />
                              <span>{{ ((animal.instance.additional != null) ? translateAttribute(animal.instance.additional.gender, 'gender') : noValuePlaceholder) || noValuePlaceholder }}</span>
                            </h3>
                            <h3 class="text-with-icon">
                              <font-awesome-icon :icon="faDna" />
                              <span>{{ ((animal.instance.additional != null) ? translateAttribute(animal.instance.additional.race, 'race') : noValuePlaceholder) || noValuePlaceholder }}</span>
                            </h3>
                            <h3 class="text-with-icon">
                              <font-awesome-icon :icon="faCakeCandles" />
                              <span v-if="animal.instance.additional != null && (animal.instance.additional.birthday != null || animal.instance.additional.birthyear != null)">
                                {{ animal.instance.additional.birthday || animal.instance.additional.birthyear }}
                                ({{ i18n.$t('animal.attributes.additional.age') }}:
                                <!-- Calculate either from the exact birthday or just the year (Handle calculation there differently) -->
                                <template v-if="animal.instance.additional.birthday == null">
                                  ~ <!-- Just an estimate from the year -->
                                  {{ calculateAgeFromBirthdate(animal.instance.additional.birthyear, ageYearFormat) }})
                                </template>
                                <template v-else>
                                  {{ calculateAgeFromBirthdate(animal.instance.additional.birthday) }})
                                </template>
                              </span>
                              <span v-else-if="animal.instance.additional != null && animal.instance.additional.age != null">
                                {{ i18n.$t('animal.attributes.additional.age') }}: {{ animal.instance.additional.age }}
                              </span>
                              <span v-else>
                                {{ noValuePlaceholder }}
                              </span>
                            </h3>
                            <h3 class="text-with-icon">
                              <font-awesome-icon :icon="faAddressCard" />
                              <template v-if="animal.instance.personal_data != null">
                                <!-- Take the first non null value -->
                                <template v-if="animal.instance.personal_data.name != null">
                                  {{ animal.instance.personal_data.name }}
                                </template>
                                <template v-else-if="animal.instance.personal_data.phone != null">
                                  {{ animal.instance.personal_data.phone }}
                                </template>
                                <template v-else-if="animal.instance.personal_data.address != null">
                                  {{ animal.instance.personal_data.address }}
                                </template>
                                <template v-else>
                                  {{ noValuePlaceholder }}
                                </template>
                              </template>
                              <template v-else>
                                {{ noValuePlaceholder }}
                              </template>
                            </h3>
                            <h3 class="text-with-icon">
                              <font-awesome-icon :icon="faHouseMedicalFlag" />
                              <span v-if="getLatestStatus(animal.instance, 'visit_reason') != null">
                                <template v-if="getLatestStatus(animal.instance, 'visit_reason').timestamp != null">
                                  {{ getSimpleLocalDate(getLatestStatus(animal.instance, 'visit_reason').timestamp) }}
                                  &centerdot;
                                </template>
                                {{ getLatestStatus(animal.instance, 'visit_reason').details.reason || noValuePlaceholder }}
                              </span>
                              <span v-else>{{ noValuePlaceholder }}</span>
                            </h3>
                            <h3 class="text-with-icon">
                              <font-awesome-icon :icon="faHandHoldingMedical" />
                              <span v-if="getLatestStatus(animal.instance, 'surgery') != null">
                                <template v-if="getLatestStatus(animal.instance, 'surgery').timestamp != null">
                                  {{ getSimpleLocalDate(getLatestStatus(animal.instance, 'surgery').timestamp) }}
                                  &centerdot;
                                </template>
                                {{ getLatestStatus(animal.instance, 'surgery').details.type || noValuePlaceholder }}
                              </span>
                              <span v-else>{{ noValuePlaceholder }}</span>
                            </h3>
                            <h3 class="text-with-icon other-grouping-indicator">
                              <font-awesome-icon :icon="(groupByStatus) ? faLocationDot : faDiagramPredecessor" />
                              <span v-if="getLatestStatus(animal.instance, (groupByStatus) ? 'location' : 'case_status') != null">
                                <template v-if="getLatestStatus(animal.instance, (groupByStatus) ? 'location' : 'case_status').timestamp != null">
                                  {{ getSimpleLocalDate(getLatestStatus(animal.instance, (groupByStatus) ? 'location' : 'case_status').timestamp) }}
                                  &centerdot;
                                </template>
                                <template v-if="groupByStatus">
                                  {{ getLatestStatus(animal.instance, 'location').details.location || noValuePlaceholder }}
                                  <template v-if="getLatestStatus(animal.instance, 'location').details.spot != null">
                                    &centerdot;
                                    {{ getLatestStatus(animal.instance, 'location').details.spot }}
                                  </template>
                                </template>
                                <template v-else>
                                  {{ getLatestStatus(animal.instance, 'case_status').details.status || noValuePlaceholder }}
                                </template>
                              </span>
                              <span v-else>{{ noValuePlaceholder }}</span>
                            </h3>
                          </div>
                        </ion-label>
                      </ion-item>

                      <!-- Either show a button to open the sliding item, or for owners just a location button -->
                      <ion-button
                        slot="end"
                        class="horse-actions-button"
                        fill="none"
                        @mousedown="setNewItemOpenState(animal)"
                        :disabled="!(enableGroupByStatus) && (animal.instance == null || animal.instance.horses.length <= 0)"
                        @click="enableGroupByStatus ? toggleSlidingItem(animal) : openMoveModal(animal, getObjectPath(getLatestStatus(animal.instance, 'location'), ['details', 'location']), getObjectPath(getLatestStatus(animal.instance, 'location'), ['details', 'spot']))">
                        <font-awesome-icon v-if="enableGroupByStatus" class="swap-positions-icon" :icon="faArrowRightArrowLeft" slot="icon-only" />
                        <div class="combined-move-location-icon" slot="icon-only" v-else>
                          <font-awesome-icon :icon="faLocationDot" />
                          <font-awesome-icon class="swap-positions-icon" :icon="faArrowRightArrowLeft" />
                        </div>
                      </ion-button>
                    </ion-item>

                    <ion-item-options side="end" class="horse-actions">
                      <ion-item-option class="move-horse-option" :disabled="animal.instance == null || animal.instance.horses.length <= 0" @click="openMoveModal(animal, getObjectPath(getLatestStatus(animal.instance, 'location'), ['details', 'location']), getObjectPath(getLatestStatus(animal.instance, 'location'), ['details', 'spot']))">
                        <font-awesome-icon :icon="faLocationDot" slot="icon-only" />
                      </ion-item-option>
                      <ion-item-option class="change-status-option" :disabled="animal.instance == null || animal.instance.horses.length <= 0" @click="openStatusModal(animal, getObjectPath(getLatestStatus(animal.instance, 'case_status'), ['details', 'status']))">
                        <font-awesome-icon :icon="faDiagramPredecessor" slot="icon-only" />
                      </ion-item-option>
                      <ion-item-option class="scan-code-option" :disabled="animal.instance == null || animal.instance.horses.length <= 0" @click="openCameraMoveModal(animal)">
                        <ion-icon class="scan-codes-icon" :icon="scanCodesIcon" slot="icon-only" ></ion-icon>
                      </ion-item-option>
                    </ion-item-options>

                  </ion-item-sliding>
                </template>
                <ion-item v-else-if="searchAnimalInput == null || searchAnimalInput.length <= 0" lines="full" class="free-spot-item">
                  <ion-label>{{ i18n.$t(`analysis.occupancy_status.${(group.descriptor != null) ? ((groupByStatus) ? 'no_animals_status' : 'free') : ((areAnimalsAvailable) ? 'no_unassigned' : 'no_animals')}`) }}</ion-label>
                </ion-item>
              </template>
            </CollapsibleList>
          </ion-list>
        </ion-card>
      </template>
      <ion-button :class="['all-qr-button', (groupByStatus) ? 'status' : undefined]" fill="clear" @click="generateAllCurrentQR(groupByStatus)">
        <ion-icon :icon="qrCode" slot="start"></ion-icon>
        <span>{{ i18n.$t(`analysis.generate_qr_all.${ groupByStatus ? 'status' : 'location'}`) }}</span>
      </ion-button>
      <div id="over-scroll"></div>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button color="primary">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button class="add-horse" @click="addAnimal()">
            <ion-icon :icon="horseIcon" class="icon"></ion-icon>
            <ion-icon class="icon-addition" :icon="add"></ion-icon>
          </ion-fab-button>
          <ion-fab-button class="add-location" @click="addLocation()">
            <font-awesome-icon :icon="faLocationDot" class="icon" />
            <ion-icon class="icon-addition" :icon="add"></ion-icon>
          </ion-fab-button>
          <ion-fab-button class="add-status" @click="addStatus()"> <!-- TODO Hide this for normal users, as well as the status in the animal Item up above-->
            <font-awesome-icon :icon="faDiagramPredecessor" class="icon" />
            <ion-icon class="icon-addition" :icon="add"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage, 
  IonHeader, 
  IonContent, 
  IonList,
  IonItem,
  IonItemSliding,
  IonItemOptions,
  IonItemOption, //TODO Remove unused CSS!!!!
  IonItemDivider,
  IonLabel,
  IonSearchbar, 
  IonIcon, 
  IonAvatar, 
  IonCard, 
  IonCardContent, 
  IonCardHeader, 
  IonCardTitle,
  IonButton,
  IonFab,
  IonFabList,
  IonFabButton,
  IonToggle,
  //createGesture
} from '@ionic/vue';
import { computed, ref, watch /* , onUpdated */, onMounted } from 'vue';
import { useStore } from 'vuex';

import MainToolbar from '@/components/MainToolbar.vue';
import CollapsibleList from '@/components/CollapsibleList.vue';
import AuthenticatedMedia from '@/components/AuthenticatedMedia.vue';

import { openMoveHorseModal, default as moveHorseModalComponent } from '@/components/MoveHorseModal.vue';
import { openChangeStatusModal, default as changeStatusModalComponent } from '@/components/ChangeStatusModal.vue';
import { default as QRGenerationModalComponent, openQRGenerationModal } from '@/components/QRGenerationModal.vue';
import { useEditAnimalModal } from '@/components/composables/EditAnimalModal.js';
import { useEditLocationModal } from '@/components/composables/EditLocationModal.js';
import { useEditCaseStatusModal } from '@/components/composables/EditCaseStatusModal.js';

import { UNASSIGNED_ID } from '@/components/composables/QR';

import { pencil, checkmarkSharp, searchSharp, enterOutline, exitOutline, add, save, closeCircleOutline, qrCode } from 'ionicons/icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faHashtag, faMarsAndVenus, faDna, faCakeCandles, faAddressCard, faLocationDot, faDiagramPredecessor, faArrowRightArrowLeft, faHouseMedicalFlag, faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';

import horseIcon from '@/assets/icons/horse.svg';

import scanCodesIcon from '@/assets/icons/scan_codes.svg';

import { useI18n } from "@/utils/i18n";
import { blurOnEnter } from '@/utils/interaction';
import { objectContainsNonNullValues, removeNonAlphaNumCharactersNormalize } from '@/utils/algorithms';
import { getSortedEntriesBySortOrder } from '@/utils/report';
import { useDayjs, ageYearFormat, ageDateFormat } from '@/utils/dayjs';
import { localErrorToast } from '@/utils/error';

import { useRouter, useRoute } from 'vue-router';

import { useQR } from '@/components/composables/QR';

import _ from 'lodash';
import { getSearchMetadata } from '@/utils/animals';

export default  {
  name: 'AnimalManagement',
  components: { 
    IonHeader, 
    IonContent, 
    IonPage, 
    MainToolbar, 
    IonList,
    IonItem,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    IonItemDivider,
    IonLabel,
    IonSearchbar, 
    IonIcon, 
    IonAvatar, 
    IonCard, 
    IonCardContent, 
    IonCardHeader, 
    IonCardTitle, 
    IonButton,
    IonFab,
    IonFabList,
    IonFabButton,
    IonToggle,
    CollapsibleList,
    AuthenticatedMedia,
    FontAwesomeIcon
  },
  setup() {
    const i18n = useI18n();

    const { calculateAgeFromBirthdate, getSimpleLocalDate, getSimpleLocalDateAndTime, dayjs } = useDayjs();

    const store = useStore();

    const router = useRouter();

    const route = useRoute();

    const { scanCode } = useQR();

    const editAnimalModal = useEditAnimalModal();

    const editLocationModal = useEditLocationModal();

    const editCaseStatusModal = useEditCaseStatusModal();

    const searchAnimalInput = ref(null);

    let translationPrefix = 'animal.horse';

    const noValuePlaceholder = '—';

    const isVeterinarian = computed(() => {
      return store.getters['auth/isVeterinarian'];
    });

    const enableGroupByStatus = computed(() => isVeterinarian.value);

    const animalSlidingItems = ref({});

    const UUIDItems = ref({});

    const isMounted = ref(false);

    /*
    Use this block as a start for drag and drop. Doesn't fully work as the item is inside a relative container and can't leave it. Also scrolling is not possible!
    const animalSlidingItemGestures = ref({});
    
    //FIXME onUpdated is unrealiable for this on change!
    onUpdated(() => {
      _.forEach(animalSlidingItemGestures.value, (currentGesture) => {
        if (currentGesture != null) currentGesture.destroy();
      });

      animalSlidingItemGestures.value = _.mapValues(animalSlidingItems.value, (item) => {
        if (item != null) {
          console.log("ADDED");
          let dragGesture = createGesture({
            el: item.$el.getElementsByClassName('horse-actions-button')[0],
            gestureName: 'draggable',
            threshold: 0,
            onStart: () => {
              console.log("START")
              item.$el.style.transition = 'none';
              item.$el.style.opacity = '0.7';
              item.$el.style.position = 'fixed';
              item.$el.style.zIndex = '2000';
            },
            onMove: (event) => {
              item.$el.style.transform = `translate(${event.deltaX}px, ${event.deltaY}px)`;
            },
            onEnd: () => {
              item.$el.style.transition = '.3s ease-out';
              item.$el.style.transform = `translate(0, 0)`;
              item.$el.style.zIndex = 'inherit';
              item.$el.style.opacity = '1';
              item.$el.style.position = 'relative';
            },
          });

          dragGesture.enable();

          return dragGesture;
        }
      });
    });*/

    const animalSlidingItemRef = function(animal, groupByStatusMode, element) {
      //Sometimes element is null, even though it exists! Prevent that!
      if (animal != null && animal.id != null && element != null) animalSlidingItems.value[(animal.id + ((groupByStatusMode) ? '_status' : ''))] = element;
    }

    const setUUIDItemReference = function(element) {
      if (element != null && element.$el != null && element.$el.id != null) {
        if (!(element.$el.id in UUIDItems.value)) UUIDItems.value = { ...UUIDItems.value, [element.$el.id]: true };
      }
    }

    const getSlidingItem = computed(() => {
      let slidingItems = animalSlidingItems.value;
      let groupByStatusMode = groupByStatus.value;
      return function(animalId) {
        return slidingItems[(animalId + ((groupByStatusMode) ? '_status' : ''))];
      }
    })

    //Save the current state of the currently touched sliding item, when the mouse or touch started inside the button (mousedown)
    //Only one is active at once and can be checked if it was clicked while dragging
    const previousItemOpenState = ref(false);

    const setNewItemOpenState = function(animal) {
      if (animal != null && animal.id != null) {
        const slidingItem = getSlidingItem.value(animal.id);
        if (slidingItem != null) {
          slidingItem.$el.getSlidingRatio().then((ratio) => {
            if (ratio > 0.5) previousItemOpenState.value = true;
            else previousItemOpenState.value = false;
          });
        }
      }
    }

    //Once a mouseup event is fired (mouse or touch was release after starting inside the button), either open or close the sliding item depending on the previous state
    //This prevents the button triggering on dragging the sliding item and reversing the open/close from the sliding action
    const toggleSlidingItem = function(animal) {
      if (animal != null && animal.id != null) {
        const slidingItem = getSlidingItem.value(animal.id);
        if (slidingItem != null) {
          if (previousItemOpenState.value) slidingItem.$el.close();
          else slidingItem.$el.open();
        }
      }
    }

    const closeSlidingItem = function(animal) {
      if (animal != null && animal.id != null) {
        const slidingItem = getSlidingItem.value(animal.id);
        if (slidingItem != null) {
          slidingItem.$el.close();
        }
      }
    }

    //Only allow grouping by status when the user is a veterinarian
    const groupByStatus = computed({
      get: () => (isVeterinarian.value && store.getters['horses/shouldGroupByStatus']),
      set: (newState) => {
        let shouldGroupByStatus = newState;
        if (!(isVeterinarian.value)) shouldGroupByStatus = false;
        store.commit('horses/setGroupByStatusState', shouldGroupByStatus);
      }
    });

    const availableAnimalsByOrder = ref(null);

    //animalsWithStatusAsyncFunction updates, if the index of the statuses or animals update
    const animalsWithStatusPromise = computed(() => store.getters['horses/getHorsesByOrderAsync']);

    //Starts filtering when one of the dependencies changes and once on load
    watch(animalsWithStatusPromise, (newAnimalPromise) => {
      if (newAnimalPromise != null) { 
        newAnimalPromise
          .then((animals) => {
            availableAnimalsByOrder.value = animals;
          })
          .catch(error => {
            console.error('Error retrieving animal data', error);
            localErrorToast(i18n, error);

            availableAnimalsByOrder.value = null;
          });
      } else {
        availableAnimalsByOrder.value = null;
      }
    }, {deep: true, immediate: true});

    const getLatestStatus = function(animalInstance, type) {
      let latestStatus = _.get(animalInstance, ['status', type, 'latest'], {});
      //Make sure it is valid
      if (latestStatus.timestamp == null || latestStatus.details == null) return null;
      return latestStatus;
    }

    const getObjectPath = function(object, path) {
      return _.get(object, path, null);
    }

    const areAnimalsAvailable = computed(() => {
      return (availableAnimalsByOrder.value != null && availableAnimalsByOrder.value.animals != null && Object.keys(availableAnimalsByOrder.value.animals).length > 0);
    });

    const filteredAnimalsByOrder = computed(() => {
      let filterString = searchAnimalInput.value;

      let animals = {};
      let locations = {};
      let statuses = {};

      if (availableAnimalsByOrder.value != null) {
        //First filter the animals
        animals = _.pickBy(availableAnimalsByOrder.value.animals, (animal) => {
          //Always include when no search term was entered!!
          if (filterString == null || filterString.length <= 0) return true;
          //Normalize search term to not include spaces
          let filterTerm = removeNonAlphaNumCharactersNormalize(filterString); 

          if (animal != null) {
            if (animal.name != null && removeNonAlphaNumCharactersNormalize(animal.name).includes(filterTerm)) return true;

            let searchMetadata = getSearchMetadata(animal, false);
            //Already normalized!
            if (searchMetadata != null && Array.isArray(searchMetadata)) {
              for (let metadataValue of searchMetadata) {
                if (metadataValue != null && metadataValue.includes(filterTerm)) return true;
              }
            }
          }
        });

        //Then filter out only the animalIds that are included above
        locations = _.mapValues(availableAnimalsByOrder.value.locations, (location) => {
          let locationCount = 0;
          let unfilteredLocationCount = 0;

          let filteredSpots = _.mapValues(location.spots, (spot) => {
            let animalsInLocation = spot['animals'];
            let unfilteredAnimalsCount = animalsInLocation.length;
            //Filter and count all animals for each spot
            let filteredAnimals = _.filter(animalsInLocation, (animalId) => (animalId != null && animalId in animals));
            let filteredAnimalsCount = filteredAnimals.length;

            locationCount += filteredAnimalsCount;
            unfilteredLocationCount += unfilteredAnimalsCount;
            return {
              ...spot,
              animals: filteredAnimals,
              count: filteredAnimalsCount,
              unfilteredCount: unfilteredAnimalsCount
            };
          });

          //Sum up the counts of all animals in the spots of this location
          return {
            ...location,
            spots: filteredSpots,
            count: locationCount,
            unfilteredCount: unfilteredLocationCount
          }
        });

        //Same filtering for the statuses
        statuses = _.mapValues(availableAnimalsByOrder.value.statuses, (status) => {
          let animalsInStatus = status['animals'];
          let unfilteredAnimalsCount = animalsInStatus.length;
          let filteredAnimals = _.filter(animalsInStatus, (animalId) => (animalId != null && animalId in animals));

          return {
            ...status,
            animals: filteredAnimals,
            count: filteredAnimals.length,
            unfilteredCount: unfilteredAnimalsCount
          };
        });
      }

      return {
        animals,
        locations,
        statuses
      }
    });

    const unfilteredOrderCriterion = computed(() => {
      if (groupByStatus.value) {
        return availableAnimalsByOrder.value.statuses;
      }
      return availableAnimalsByOrder.value.locations; 
    })

    const currentOrderCriterion = computed(() => {
      if (groupByStatus.value) {
        return filteredAnimalsByOrder.value.statuses;
      }
      return filteredAnimalsByOrder.value.locations;
    });

    //By default, the unassigned group is always, present, if more than that, then groups are available
    const areOrderGroupsAvailable = computed(() => {
      return (unfilteredOrderCriterion.value != null && Object.keys(unfilteredOrderCriterion.value).length > 1);
    });

/*     const doesAnyGroupContainFilteredAnimals = computed(() => {
      return _.some(currentOrderCriterion.value, (group) => group.count > 0);
    });

    const doesUnassignedContainFilteredAnimals = computed(() => {
      if (groupByStatus.value) {
        return _.get(filteredAnimalsByOrder.value.statuses, ['null', 'count'], 0) > 0;
      }
      return _.get(filteredAnimalsByOrder.value.locations, ['null', 'spots', 'null', 'count'], 0) > 0;
    }); */

    //Returns spots for locations or an object with just one element for statuses for compatibility in the template
    const getSecondLevelOrderCriterion = function(orderCriterion) {
      if (orderCriterion.spots != null) {
        return orderCriterion.spots;
      } else if (orderCriterion.animals != null) {
        return { null: _.pick(orderCriterion, ['animals', 'count'])};
      }
      return null;
    }

    const sortOrderCriterion = function(orderCriterion) {
      return getSortedEntriesBySortOrder(orderCriterion, false, 'order');
    }

    const getAnimals = computed(() => {
      return function(animalIds) {
        return _.map(_.pick(filteredAnimalsByOrder.value.animals, animalIds), (animal, animalId) => {
          return { instance: animal, id: animalId};
        });
      };
    });

    const getSearchCount = computed(() => {
      //If no search term was entered, or no aniamls are visible, return null to indicate no animals
      if (searchAnimalInput.value == null || searchAnimalInput.value.length <= 0) return function() { return null };
      return function(orderGroup) {
        if (orderGroup != null && orderGroup.count > 0) return orderGroup.count;
        return null;
      }
    });

    const openStatusModal = function(animal, status, selectUUID) {
      let now = dayjs.utc().toISOString();
      closeSlidingItem(animal);
      openChangeStatusModal(changeStatusModalComponent, _.get(availableAnimalsByOrder.value, ['statuses']), _.get(availableAnimalsByOrder.value, ['animals']), animal, {status, selectUUID}).then((changedHorses) => {
        _.forEach(changedHorses.data, (update, animalId) => {
          let creationPromise;
          //Create new status
          if (update.new) {
            creationPromise = addStatus();
          } else {
            creationPromise = Promise.resolve(update);
          }

          creationPromise.then((newStatus) => {
            return store.dispatch('horses/addStatus', { status: {
              timestamp: now,
              type: 'case_status',
              horse: store.getters['horses/getNewestHorseIdForPersonalInfoId'](animalId),
              details: newStatus
            } }).then((uploadStatus) => uploadStatus.key);
          });
        });
      });

    }

    const openMoveModal = function(animal, location, spot, selectUUID) {
      let now = dayjs.utc().toISOString();
      closeSlidingItem(animal);
      openMoveHorseModal(moveHorseModalComponent, _.get(availableAnimalsByOrder.value, ['locations']), _.get(availableAnimalsByOrder.value, ['animals']), animal, {location, spot, selectUUID}).then((movedHorses) => {
        _.forEach(movedHorses.data, (update, animalId) => {
          let creationPromise;
          //Create new location
          if (update.new) {
            creationPromise = addLocation();
          } else {
            creationPromise = Promise.resolve(update);
          }

          creationPromise.then((newLocation) => {
            return store.dispatch('horses/addStatus', { status: {
              timestamp: now,
              type: 'location',
              horse: store.getters['horses/getNewestHorseIdForPersonalInfoId'](animalId),
              details: newLocation
            } }).then((uploadStatus) => uploadStatus.key);
          });
        });
        //horseLocationChanges.value = _.clone(_.assign(horseLocationChanges.value, movedHorses.data)); //Clone on change to trigger new computed event
      });
    }

    const openCameraMoveModal = function(animal) {
      closeSlidingItem(animal);
      scanCode(undefined, true).then((code) => {
        if (code != null && code.isInternalCode) {
          let type = code.processedValue.type;
          let uuid = code.processedValue.uuid;
          if (type != null && uuid != null) {
            switch (type) {
              case 'location': {
                let currentLocation = getLatestStatus(animal.instance, 'location');
                openMoveModal(animal, getObjectPath(currentLocation, ['details', 'location']), getObjectPath(currentLocation, ['details', 'spot']), uuid);
                break;
              }

              case 'case-status': {
                let currentStatus = getLatestStatus(animal.instance, 'case_status');
                openStatusModal(animal, getObjectPath(currentStatus, ['details', 'status']), uuid);
                break;
              }
            
              default:
                break;
            }
          }
        }
      }).catch(() => null);
    }

    const chooseSubject = function(id) {
      router.push({name: 'view-animal', params: { animalId: id }});
    }

    const editLocation = function(locationObject, viewMode = false) {
      editLocationModal.open(locationObject, viewMode);
    }

    const editStatus = function(statusObject, viewMode = false) {
      return editCaseStatusModal.open(statusObject, viewMode);
    }

    const addAnimal = function() {
      return editAnimalModal.open(null, searchAnimalInput.value);
    }

    const addLocation = function() {
      return editLocation(null);
    }

    const addStatus = function() {
      return editStatus(null);
    }

    const getUUID = function(firstOrder, secondOrder) {
      if (firstOrder.descriptor == null) return UNASSIGNED_ID;
      //Location is saved in second order, status in first order!
      return _.get(secondOrder, ['uuid'], _.get(firstOrder, ['uuid']));
    }

    const setupParametersForSingleOrderCriterion = function(firstOrder, secondOrder, isStatus) {
      let allowCustomQRCode = (firstOrder.descriptor != null);

      let parameters = {
        id: getUUID(firstOrder, secondOrder),
        allowCustomQRCode,
        generateRandomID: allowCustomQRCode
      };

      if (isStatus) {
        parameters.status = firstOrder.descriptor || i18n.$t('analysis.subject.not_present');
      } else {
        parameters.location = firstOrder.descriptor || i18n.$t('analysis.subject.unassigned');
        parameters.spot = secondOrder.descriptor;
      }

      return parameters;
    }

    const createUniqueItemID = function(uuid) {
      return `animal-management-${uuid}`;
    }

    const HIGHLIGHT_CLASS = 'highlighted-scroll-target';
    const HIGHLIGHT_TIME = 4000;

    const scrollItemIntoView = function(itemInstance) {
      if (itemInstance != null) {
        setTimeout(() => { //Dispatch to wait for mounting to complete
          itemInstance.dispatchEvent(new CustomEvent('requestScrollToItem')); //Open any lists that the item is inside of
          setTimeout(() => { 
            itemInstance.scrollIntoView({ behavior: 'smooth', block: 'center' });
            itemInstance.classList.add(HIGHLIGHT_CLASS);
          }, 100); //Defer to outside of the event listener after the parent list has opened
          setTimeout(() => { 
            itemInstance.classList.remove(HIGHLIGHT_CLASS);
            //Remove hash from route again
            let currentRoute = router.currentRoute.value;
            currentRoute.hash = undefined;
            router.replace(currentRoute);
          }, HIGHLIGHT_TIME); //Remove highlight after specified time
        }, 500);
      }
    }

    watch([route, UUIDItems, isMounted], ([newRoute, newItems, currentMountStatus]) => {
      if (!(currentMountStatus)) return;
      let hash = createUniqueItemID(_.trim(newRoute.hash, '#'));
      if (hash != null && newItems != null && newItems[hash] != null) {
        let element = document.getElementById(hash);
        if (element != null) {
          scrollItemIntoView(element); //FIXME Does not open anymore!
        }
      }
    }, { immediate: true });

    onMounted(() => isMounted.value = true);

    //Generates QR codes for all the given parameterObjects
    const generateQR = function(parameterObjects, isStatus) {
      //TODO Adapt for status too and get more info where to set the new parameters!
      openQRGenerationModal(QRGenerationModalComponent, parameterObjects, ((isStatus) ? 'case-status' : 'location'), ((isStatus) ? 'tertiary' : 'primary')).then((codes) => {
        let codeArray = _.get(codes, ['data', 'codes'], []);

        //FIXME Statuses and locations that are only there because of animals that still have it attached, can't get codes added. Maybe remove buttons for those! Or get a different list from further up!
        if (codeArray.length) {
          if (isStatus) {
            let codeObject = {};
            _.forEach(codeArray, (code) => {
              //Only set the code, if modified
              if (_.get(code, ['params', 'isModified'], false)) {
                let status = _.get(code, ['params', 'originalObject', 'status'], null);

                let id = _.get(code, ['params', 'id']);

                if (id != null) _.setWith(codeObject, [status], id, Object);
              }
            });

            if (Object.keys(codeObject).length) {
              let currentStatuses = _.cloneDeep(store.getters['customization/getStatuses']) || [];

              let newStatuses = _.map(currentStatuses, (status) => {
                let newStatus = {
                  ...status
                }

                let codeForStatus = _.get(codeObject, [status.descriptor]);
                if (codeForStatus != null) newStatus.uuid = codeForStatus;

                return newStatus;
              });

              return store.dispatch('customization/updateStatuses', newStatuses).then((uploadStatus) => uploadStatus.key);
            }
          } else {
            let codeObject = {};
            _.forEach(codeArray, (code) => {
              //Only set the code, if modified
              if (_.get(code, ['params', 'isModified'], false)) {
                let location = _.get(code, ['params', 'originalObject', 'location'], null);
                let spot = _.get(code, ['params', 'originalObject', 'spot'], null);

                let id = _.get(code, ['params', 'id']);

                if (id != null) _.setWith(codeObject, [location, spot], id, Object);
              }
            });

            if (Object.keys(codeObject).length) {
              let currentLocations = _.cloneDeep(store.getters['customization/getLocations']) || [];

              let newLocations = _.map(currentLocations, (location) => {
                return {
                  ...location,
                  spots: _.map(location.spots, (spot) => {
                    let newSpot = {
                      ...spot
                    }

                    let codeForSpot = _.get(codeObject, [location.descriptor, spot.descriptor]);
                    if (codeForSpot != null) newSpot.uuid = codeForSpot;

                    return newSpot;
                  })
                }
              });
              
              return store.dispatch('customization/updateLocations', newLocations).then((uploadStatus) => uploadStatus.key);
            }
          }
        }
      });
      //TODO When creating automatically assign UUID?
      //TODO Add to editing modal to create QR for all spots in one location
    }

    const generateAllCurrentQR = function(isStatus) {
      let parameterObjects = [];
      let orderCriterion;
      if (isStatus) {
        orderCriterion = filteredAnimalsByOrder.value.statuses;
      } else {
        orderCriterion = filteredAnimalsByOrder.value.locations;
      }
      
      for (let [, group] of sortOrderCriterion(orderCriterion)) {
        for (let [, secondLevelGroup] of sortOrderCriterion(getSecondLevelOrderCriterion(group))) {
          parameterObjects.push(setupParametersForSingleOrderCriterion(group, secondLevelGroup, isStatus));
        }
      }

      generateQR(_.compact(parameterObjects), isStatus);
    }

    const generateSingleQR = function(firstOrder, secondOrder, isStatus) {
      generateQR([setupParametersForSingleOrderCriterion(firstOrder, secondOrder, isStatus)], isStatus);
    }

    return { 
      i18n,
      translationPrefix,
      isVeterinarian,
      enableGroupByStatus,
      animalSlidingItemRef,
      setUUIDItemReference,
      setNewItemOpenState,
      toggleSlidingItem,
      groupByStatus,
      noValuePlaceholder,
      getLatestStatus,
      getObjectPath,
      areAnimalsAvailable,
      currentOrderCriterion,
      areOrderGroupsAvailable,
/*       doesAnyGroupContainFilteredAnimals,
      doesUnassignedContainFilteredAnimals, */
      getSecondLevelOrderCriterion,
      sortOrderCriterion,
      getAnimals,
      getSearchCount,
      objectContainsNonNullValues,
      openStatusModal,
      openMoveModal,
      openCameraMoveModal,
      chooseSubject,
      addAnimal,
      editLocation,
      addLocation,
      editStatus,
      addStatus,
      getUUID,
      createUniqueItemID,
      generateQR,
      generateAllCurrentQR,
      generateSingleQR,
      blurOnEnter, 
      calculateAgeFromBirthdate,
      getSimpleLocalDate,
      getSimpleLocalDateAndTime,
      ageYearFormat,
      ageDateFormat,
      translateAttribute: editAnimalModal.translateAttribute,
      searchAnimalInput,
      faHashtag,
      faMarsAndVenus,
      faDna,
      faCakeCandles,
      faAddressCard,
      pencil,
      checkmarkSharp,
      searchSharp,
      faLocationDot,
      faDiagramPredecessor,
      faArrowRightArrowLeft,
      faHouseMedicalFlag,
      faHandHoldingMedical,
      enterOutline,
      exitOutline,
      save,
      add,
      closeCircleOutline,
      qrCode,
      horseIcon,
      scanCodesIcon
    };
  }
}
</script>

<style>
.searchbar-input {
  padding-right: 5px!important;
}

.discard-danger {
  color: var(--ion-color-danger)!important;
}

.discard-save {
  color: var(--ion-color-success-shade)!important;
}
</style>

<style scoped>
#over-scroll {
  width: 100%;
  height: 100px;
}

ion-list-header {
  font-size: 1.2em;
  font-weight: 500;
  --color: var(--ion-color-step-850, #262626);
}

.subject-search-bar, ion-searchbar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  padding-left: 0px;
  padding-right: 0px;
  background: var(--ion-background-color, #fff);
  --background: var(--ion-card-background, #fff);
}

.hint-card ion-item {
  --background: var(--ion-card-background, #fff);
}
.hint-card ion-item ion-label {
  text-align: center;
  white-space: normal;
  font-weight: bold;
}

.add-icon-hint-container {
  vertical-align: middle;
}

.add-icon-hint {
  font-size: 1.5em;
}

.avatar-icon {
  border-radius: var(--border-radius);
  background: var(--background, white);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
}

.avatar {
  background: var(--ion-color-quaternary);
  padding: 2px;
  width: 60px;
  height: 60px;
}

.avatar-icon > * {
  color: var(--ion-color-quaternary);
  width: 100%;
  height: 100%;
}

.text-with-icon {
  display: flex;
  align-items: center;
}

.text-with-icon svg {
  min-width: 1.9em;
}

.outer-horse-item {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

.inner-horse-item {
  flex-grow: 1;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  margin-bottom: 1px;
}

.inner-horse-item ion-label {
  --color: var(--ion-color-step-950, #595959);
}

.horse-list-item ion-label {
  display: flex;
  flex-flow: column;
}

.horse-list-item ion-label > * {
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.collapsible-list-label {
  font-weight: normal;
  white-space: normal;
  font-size: 1.15em;
}

.ios ion-card {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ios ion-card-title {
  font-size: 1.5em;
}

.ios ion-card-header ion-button {
  font-size: 1em;
}

.ios .collapsible-list-label {
  font-size: 1em;
}

.subject-list {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: -2px;
  background-color: var(--ion-card-background, #fff);
}

.subject-list ion-item {
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.subject-list ion-item-divider {
  color: var(--ion-color-primary-text);
  background: rgba(var(--ion-color-medium-rgb), 0.15);
  font-weight: 600;
  transition: background 0.5s ease-in;
}

.subject-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.subject-list .collapsible-list {
  --color: var(--ion-color-primary-text);
  color: var(--color);
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.status-list .collapsible-list {
  --color: var(--ion-color-tertiary-text);
}

.group-label {
  font-weight: 600;
  color: var(--ion-color-primary-text);
}

.unset-group-label {
  font-style: italic;
  color: var(--ion-color-primary-text);
}

.status-list .group-label, .status-list .unset-group-label {
  color: var(--ion-color-tertiary-text);
}

.all-qr-button {
  text-transform: none;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-left: 10px;
  --padding-right: 10px;
  width: auto;
  height: auto;
  color: var(--ion-color-primary-text);
}

.all-qr-button.status {
  color: var(--ion-color-tertiary-text);
}

.all-qr-button ion-icon {
  min-width: 32px;
  font-size: 2em;
}

.all-qr-button span {
  flex-shrink: 1;
  flex-basis: auto;
  white-space: normal;
  text-align: start;
}

.subject-search-bar, .all-qr-button {
  display: flex;
  margin-inline: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.ios .subject-search-bar, .ios .all-qr-button {
  margin-inline: 16px;
  margin-left: 16px;
  margin-right: 16px;
}

.subject-search-bar ion-button {
  margin-top: 10px;
  margin-bottom: 10px;
  --padding-start: 12px;
  --padding-end: 12px;
  font-size: 1.25em;
  height: unset;
  --border-width: 2px;
  --border-style: solid;
  --border-color: var(--ion-color-primary);
}

.subject-search-bar ion-button.success-shade {
  --border-color: var(--ion-color-success-shade, #28ba62);
  --background: var(--ion-color-success-shade, #28ba62);
  --background-activated: var(--ion-color-success, #2dd36f);
  --background-focused: var(--ion-color-success, #2dd36f);
  --background-hover: var(--ion-color-success, #2dd36f);
  --color: var(--ion-color-success-contrast, black);
}

.subject-search-bar ion-button > * {
  width: 1em;
}

.subject-search-bar ion-button > fontawesome-icon {
  width: 1em;
}

.subject-search-bar ion-button > .edit-icon-addition {
  position: absolute;
  font-size: 18px;
  margin-top: 6px;
  margin-left: 20px;
  transform: rotate(-10deg);
}

.subject-search-bar ion-button > .save-icon-addition {
  position: absolute;
  font-size: 14px;
  margin-top: 16px;
  margin-left: 24px;
}

.add-status {
  --background: var(--ion-color-tertiary);
  --color: var(--ion-color-tertiary-contrast);
  --color-activated: var(--ion-color-tertiary-contrast);
  --color-focused: var(--ion-color-tertiary-contrast);
}

.add-location {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
  --color-activated: var(--ion-color-primary-contrast);
  --color-focused: var(--ion-color-primary-contrast);
}

.add-horse {
  --background: var(--ion-color-quaternary);
  --color: var(--ion-color-quaternary-contrast);
  --color-activated: var(--ion-color-quaternary-contrast);
  --color-focused: var(--ion-color-quaternary-contrast);
}

.add-status .icon {
  height: 45%;
  width: 45%;
  margin-right: 5px;
  margin-bottom: 5px;
}

.add-horse .icon {
  height: 90%;
  width: 90%;
}

.add-location .icon {
  height: 55%;
  width: 55%;
  margin-right: 5px;
}

.add-status > .icon-addition {
  position: absolute;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 18px;
}

.add-location > .icon-addition {
  position: absolute;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 18px;
}

.add-horse > .icon-addition {
  position: absolute;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 18px;
}

.horse-actions-button {
  height: 100%;
  --padding-start: 12px;
  --padding-end: 12px;
  margin-inline: 0px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  --color: var(--ion-color-primary);
  font-size: 1em;
}

.combined-move-location-icon {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.horse-actions > ion-item-option > svg {
  min-width: 1.2em;
}

.horse-actions > ion-item-option > * {
  opacity: 0.8;
}

.change-status-option {
  --background: var(--ion-color-tertiary);
  --color: var(--ion-color-tertiary-contrast);
}

.scan-code-option {
  --background: linear-gradient(to bottom right, var(--ion-color-primary), var(--ion-color-tertiary));
}

.free-spot-item {
  text-align: center;
  color: var(--ion-color-success-shade);
}

.cancel-location-button {
  text-transform: none;
  font-weight: bold;
}

.subject-search-bar .cancel-location-button {
  --padding-start: 8px;
  --padding-end: 8px;
}

.cancel-location-button ion-icon {
  font-size: 1.25em;
}

.edit-group-button {
  margin: 0px;
  width: 40px;
  height: 40px;
  font-size: 12px;
  --padding-start: 0px;
  --padding-end: 0px;
  color: var(--ion-color-primary-text);
}

.status-list .edit-group-button {
  color: var(--ion-color-tertiary-text);
}

.other-grouping-indicator {
  color: var(--ion-color-tertiary-text);
}

.status-list .other-grouping-indicator {
  color: var(--ion-color-primary-text);
}

.toggle-item ion-card-title {
  font-size: 1.25em;
}

.toggle-item ion-card-header {
  padding-bottom: 10px;
}

.toggle-item {
  margin-bottom: 20px!important;
}

.toggle-item ion-label {
  font-size: 1.1em;
  hyphens: auto;
  white-space: normal;
  overflow-wrap: anywhere;
  color: var(--ion-text-color);
}

.toggle-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.swap-positions-icon {
  transform: rotate(90deg);
  width: 1.2em;
  height: 1.2em;
}

.toggle-title-icon {
  color: var(--ion-color-primary);
  margin-right: 10px;
}

.toggle-select-icon {
  font-size: 1.5em;
  margin-inline: 10px;
  color: var(--ion-text-color);
}

.toggle-container:not(.checked) .toggle-unchecked-icon {
  color: var(--ion-color-primary);
}

.toggle-container.checked .toggle-checked-icon {
  color: var(--ion-color-tertiary);
}

.toggle-container:not(.checked) .toggle-unchecked {
  color: var(--ion-color-primary-text);
}

.toggle-container.checked .toggle-checked {
  color: var(--ion-color-tertiary-text);
}

#grouping-toggle {
  flex-shrink: 0;
  margin-left: 10px;
  margin-right: 10px;
  --background: var(--ion-color-step-500, var(--ion-color-light));
  --background-checked: var(--ion-color-step-500, var(--ion-color-light));
  --handle-background: var(--ion-color-primary);
  --handle-background-checked: var(--ion-color-tertiary);
}

.extra-animal-info {
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr;
}

.extra-animal-info h3 {
  white-space: normal
}

@media (min-width: 800px) {
  .extra-animal-info {
    grid-template-columns: 1fr 1fr;
  }
}

.qr-button {
  height: 36px;
  width: 36px;
  --padding-start: 0px;
  --padding-end: 0px;
  margin-inline-end: 10px;
  color: var(--ion-color-primary-text);
}

.status-list .qr-button {
  color: var(--ion-color-tertiary-text);
}

.qr-button.unassigned .qr-icon {
  margin-right: 5px;
}

.qr-button.unassigned {
  opacity: 0.7;
}

.add-qr-icon-addition {
  display: none;
  position: absolute;
  font-size: 1em;
}

.qr-button.unassigned .add-qr-icon-addition {
  display: block;
  right: 0;
  bottom: 3px;
}

.highlighted-scroll-target {
  background: rgba(var(--ion-color-primary-rgb),0.3)!important;
}

/* .horse-list-item {
  transition: border-color 0.5s ease-in;
  border-color: transparent;
}

.highlighted-scroll-target ~ .horse-list-item {
  border-color: rgba(var(--ion-color-primary-rgb),0.3)!important;
  border: 6px solid;
} */ /* FIXME If anywhere before is also a valid location it gets highlighted as well even though it is not in that spot!!! */


</style>
